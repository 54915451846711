import React, { useState } from "react";
import { Badge, Divider, Dropdown, Layout, Menu } from "antd";
import {
  LogoutOutlined,
  UserOutlined,
  MenuFoldOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import "antd/dist/antd.css";
import "../../index.css";
import { Avatar } from "antd";
import SideMenu from "./SideMenu";
import { Content, Header } from "antd/lib/layout/layout";

import { useHistory } from "react-router-dom";

const AppRoute = ({ children }) => {
  const history = useHistory();
  const handleClick = () => {
    localStorage.setItem("auth", "");
    history.push("/login");
  };
  function handleMenuClick(e) {}

  const handleClickInfo = () => {
    history.push("/userinfo");
  };
  /* Data load dùng chung
   **/
  const profile = JSON.parse(localStorage.getItem("auth"));
  /* Data load dùng chung
   **/
  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item
        onClick={handleClickInfo}
        key="0"
        icon={<InfoCircleOutlined />}
      >
        {profile.NickName}
      </Menu.Item>
      <Menu.Item onClick={handleClick} key="1" icon={<LogoutOutlined />}>
        Thoát
      </Menu.Item>
    </Menu>
  );

  const [collapsed, setCollapsed] = useState(true);
  const toggle = () => {
    setCollapsed(!collapsed);
  };
  return (
    <Layout style={{ minHeight: "100vh" }}>
      <SideMenu collapsed={collapsed} />
      <Layout>
        <Header
          className="site-layout-sub-header-background"
          style={{ padding: 0 }}
        >
          {React.createElement(
            collapsed ? MenuFoldOutlined : MenuFoldOutlined,
            {
              className: "trigger",
              onClick: toggle,
            }
          )}
          <div style={{ float: "right", paddingRight: "12px" }}>
            <Divider type="vertical" />
            <Dropdown key={1} overlay={menu}>
              <Badge count={0}>
                <Avatar icon={<UserOutlined />} />
              </Badge>
            </Dropdown>
          </div>
        </Header>
        <Content style={{ margin: "10px 10cdpx 0" }}>
          <div className="site-layout-background" style={{ padding: 15 }}>
            {children}
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};

export default AppRoute;
