import React, { useEffect, useState } from "react";
import { DatePicker, Select, Button, Table, PageHeader } from "antd";
import API from "../../api";
import moment from "moment";
import { ArrowRightOutlined, SearchOutlined } from "@ant-design/icons";
import { Excel } from "antd-table-saveas-excel";
const { RangePicker } = DatePicker;
export default function BaoCao() {
  const profile = JSON.parse(localStorage.getItem("auth"));
  const dateFormat = "DD/MM";
  /*states*/
  const [phongbans, setPhongBans] = useState();
  const [filter, setFilter] = useState(profile.ID_PhongBan);
  const [filterDate, setFilterDate] = useState([
    moment().startOf("month"),
    moment().endOf("month"),
  ]);

  const [dataSearch, setDataSearch] = useState([]); //dữ liệu tìm được
  const [data, setData] = useState([]); // truyền vào dữ liệu table
  /* end states**/

  /**
   * useEffect
   */
  useEffect(() => {
    let content = { storename: "QLCC_GetPhongBan", param: "" };
    const loadPhongban = async () => {
      let response = await API.post("/databy", content);
      setPhongBans(response.data);
    };
    loadPhongban();
  }, []);

  useEffect(() => {
    console.log("effect");
    const loadData = async () => {
      let storename = "get_DonNghiPhepByPara";
      let from = moment(filterDate[0]).format("YYYY-MM-DD");
      let to = moment(filterDate[1]).format("YYYY-MM-DD");
      let param = `${filter};${from};${to} 23:59:58`;
      let content = { storename: storename, param: param };
      const response = await API.post("/databy", content);
      setDataSearch(response.data);
      setData(response.data);
    };
    loadData();
  }, [filter, filterDate]);
  /**
   * End useEffect
   */

  /**functions
   *
   */
  // const FilterByNameInput = (
  //   <Input
  //     prefix={<SearchOutlined />}
  //     placeholder="Gõ tìm theo tên, lý do nghỉ, trạng thái"
  //     //value={value}
  //     onChange={(e) => {
  //       const currValue = e.target.value;
  //       //setValue(currValue);
  //       const filteredData = data.filter(
  //         (entry) =>
  //           entry.lydonghi
  //             .toString()
  //             .toLowerCase()
  //             .includes(currValue.toLowerCase()) ||
  //           entry.TenNhanVien.toString()
  //             .toLowerCase()
  //             .includes(currValue.toLowerCase()) ||
  //           entry.trangthai
  //             .toString()
  //             .toLowerCase()
  //             .includes(currValue.toLowerCase())
  //       );
  //       setDataSearch(filteredData);
  //     }}
  //   />
  // );
  const columns = [
    {
      title: "Mã NV",
      key: "MaNV",
      dataIndex: "MaNV",
      sorter: (a, b) => a.MaNV.localeCompare(b.MaNV),
    },
    {
      title: "Khoa Phòng",
      key: "tenphongban",
      dataIndex: "tenphongban",
      sorter: (a, b) => a.tenphongban.localeCompare(b.tenphongban),
    },
    {
      title: "Họ lót",
      key: "HoLotNhanVien",
      dataIndex: "HoLotNhanVien",
      sorter: (a, b) => a.HoLotNhanVien.localeCompare(b.HoLotNhanVien),
    },
    {
      title: "Tên ",
      key: "TenNhanVien",
      dataIndex: "TenNhanVien",
      sorter: (a, b) => a.TenNhanVien.localeCompare(b.TenNhanVien),
    },
    {
      title: "Gửi lúc",
      key: "taoluc",
      dataIndex: "taoluc",
    },
    {
      title: "Nghỉ từ ngày",
      key: "nghitungay",
      dataIndex: "nghitungay",
    },
    {
      title: "Nghỉ đến hết ngày",
      key: "nghidenngay",
      dataIndex: "nghidenngay",
    },
    {
      title: "Tính chủ nhật",
      key: "tinhChuNhat",
      dataIndex: "tinhChuNhat",
    },
    {
      title: "Số ngày nghỉ",
      key: "songaynghi",
      dataIndex: "songaynghi",
      sorter: (a, b) => parseInt(a.songaynghi) - parseInt(b.songaynghi),
      defaultSortOrder: "descend",
    },
    {
      title: "Số ngày tính phép",
      key: "soNgayTinhPhep",
      dataIndex: "soNgayTinhPhep",
      sorter: (a, b) => parseInt(a.soNgayTinhPhep) - parseInt(b.soNgayTinhPhep),
    },
    {
      title: "Lý do",
      key: "lydonghi",
      dataIndex: "lydonghi",
    },
    {
      title: "Trạng thái",
      dataIndex: "trangthai",
      key: "trangthai",
      sorter: (a, b) => a.trangthai.localeCompare(b.trangthai),
    },
    {
      title: "Sửa lần cuối bởi",
      key: "SuaBoi",
      dataIndex: "SuaBoi",
      sorter: (a, b) => a.SuaBoi.localeCompare(b.SuaBoi),
    },
    {
      title: "Sửa lần cuối",
      key: "lastEdit",
      dataIndex: "lastEdit",
      sorter: (a, b) => a.lastEdit.localeCompare(b.lastEdit),
    },
    {
      title: "Loại phép",
      key: "loaiNgaynghi",
      dataIndex: "loaiNgaynghi",
      sorter: (a, b) => a.loaiNgaynghi.localeCompare(b.loaiNgaynghi),
    },
  ];
  const handleClick = () => {
    let from = moment(filterDate[0]).format("YYYY-MM-DD");
    let to = moment(filterDate[1]).format("YYYY-MM-DD");
    console.log(from, to, filter);
    console.log(data);
  };
  const handleOutExcel = () => {
    const excel = new Excel();
    let on = moment().format("YYYY-MM-DD");
    excel
      .addSheet(on)
      .addColumns(columns)
      .addDataSource(dataSearch, {
        str2Percent: true,
      })
      .saveAs("DataOut.xlsx");
  };
  const handleChangeDate = (e) => {
    setFilterDate([e[0]._d, e[1]._d]);
  };
  const handleChangeSelect = (e) => {
    setFilter(e);
  };
  return (
    <div>
      <PageHeader
        className="site-page-header"
        subTitle="Thống kê đơn nghỉ phép theo ngày gửi"
        extra={[
          <Button
            key="0"
            onClick={handleClick}
            type="primary"
            style={{ padding: "0px", margin: "0px" }}
            shape="circle"
            icon={<SearchOutlined />}
          />,

          <RangePicker
            key="1"
            style={{ width: "150px", margin: "0px" }}
            format={dateFormat}
            placeholder={["Từ ", " Đến"]}
            defaultValue={[moment().startOf("month"), moment().endOf("month")]}
            onChange={handleChangeDate}
          />,
          <Select
            key="2"
            onChange={handleChangeSelect}
            style={{ width: "150px", margin: "0px" }}
            showSearch
            value={filter}
            allowClear
            placeholder="Gõ tìm theo phòng ban"
            optionFilterProp="children"
          >
            {phongbans &&
              phongbans.map((item, index) =>
                profile.roleName.indexOf("nhansu") > 0 ||
                profile.roleName.indexOf("admin") > 0 ||
                profile.ID_PhongBan === item.value ||
                profile.ID_PhongBan_QuanLy === item.value ||
                profile.ID_PhongBan_QuanLy1 === item.value ||
                profile.ID_PhongBan_QuanLy2 === item.value ? (
                  <Select.Option key={index} value={item.value}>
                    {item.label}
                  </Select.Option>
                ) : (
                  <Select.Option key={index} value={item.value} disabled>
                    {item.label}
                  </Select.Option>
                )
              )}
          </Select>,
          <Button
            danger
            key="5"
            onClick={handleOutExcel}
            type="secondary"
            style={{ padding: "0px", margin: "0px" }}
            icon={<ArrowRightOutlined />}
          >
            Excel
          </Button>,
        ]}
      />

      <Table
        pagination={{
          position: ["topLeft"],
          showTotal: (total) => `Tổng có ${dataSearch.length} đơn`,
          total: dataSearch.length,
          showQuickJumper: true,
        }}
        expandable={{
          expandedRowRender: (record) => <p style={{ margin: 0 }}></p>,
          rowExpandable: (record) => record.lydonghi !== null,
        }}
        dataSource={dataSearch}
        columns={columns}
      />
    </div>
  );
}
