import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import AppRoute from "./components/App";
import getStore from "./store/getStore";
import { Router, Switch, Route } from "react-router-dom";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import { createHashHistory } from "history";
import "./index.css";
import Login from "./pages/login/index.jsx";
import routes from "./routes";
import common_vi from "./language/vi/common.json";
import common_en from "./language/en/common.json";
import "antd/dist/antd.min.css";

const history = createHashHistory();
i18next.init({
  interpolation: { escapeValue: false }, // React already does escaping
  lng: localStorage.getItem("language"), // language to use
  fallbackLng: "vi",
  debug: true,
  resources: {
    vi: {
      common: common_vi, // 'common' is our custom namespace
    },
    en: {
      common: common_en,
    },
  },
});
const { store } = getStore();

ReactDOM.render(
  <I18nextProvider i18n={i18next}>
    <Provider store={store}>
      <Router history={history}>
        <Switch>
          {routes.map((prop, key) => (
            <AppRoute
              key={key}
              path={prop.path}
              exact
              component={prop.component}
            ></AppRoute>
          ))}
          <Route path="/login" exact component={Login}></Route>
        </Switch>
      </Router>
      {/* <App /> */}
    </Provider>
  </I18nextProvider>,
  document.getElementById("root")
);
