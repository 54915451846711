import React from "react";
import { Menu } from "antd";
import { Link } from "react-router-dom";

const DynamicMenu = ({ routes }) => {
  // Điều kiện phân quyền người dùng

  // Lọc các tuyến đường phù hợp dựa trên quyền của người dùng
  const filteredRoutes = routes.filter((route) => {
    // Kiểm tra logic phân quyền ở đây
    // Ví dụ: Kiểm tra nếu người dùng có quyền truy cập tuyến đường này
    return true; // Trả về true nếu người dùng có quyền, false nếu không
  });

  return (
    <Menu theme="dark" mode="inline" defaultSelectedKeys={["4"]}>
      {filteredRoutes.map((route, index) => (
        <Menu.Item key={index} icon={route.icon}>
          <Link to={route.path}>{route.name}</Link>
        </Menu.Item>
      ))}
    </Menu>
  );
};

export default DynamicMenu;
