import React from "react";
import {
  HomeOutlined,
  AlignLeftOutlined,
  // TeamOutlined,
  InfoCircleOutlined,
  BarChartOutlined,
} from "@ant-design/icons";
// import Users from "./components/Users";
// import Counter from "./components/Counter";
import NghiPhep from "./pages/nghiphep";
import Dashboard from "./pages/dashboard";
import UserInfo from "./pages/userinfo";
//import DrafItem from "./pages/dragItem";

// import Registration from "./pages/registration";
// import Search from "./pages/Search";
import BaoCao from "./pages/BaoCao";
import { DaoTao } from "./pages/DaoTao";
import MixDuLieu from "./pages/MixDuLieu";
//const profile = JSON.parse(localStorage.getItem("auth"));
// var quyen = JSON.parse(profile.Quyen);
// console.log(quyen);
// let tam = quyen[0];
// console.log(tam);
// console.log(Object.is(tam));
const dashboardRoutes = [
  {
    key: "1",
    path: "/",

    name: "Nhà",
    label: "Nhà",
    layout: "",
    icon: <HomeOutlined />,
    component: Dashboard,
  },
  {
    key: "2",
    path: "/donnghiphep",
    name: "Đơn nghỉ phép",
    label: "Đơn nghỉ phép",
    layout: "",
    icon: <AlignLeftOutlined />,
    component: NghiPhep,
  },
  {
    key: "3",
    path: "/baocao",
    name: "Báo cáo.",
    label: "Báo cáo",
    layout: "",
    icon: <BarChartOutlined />,
    component: BaoCao,
  },
  {
    key: "4",
    path: "/userinfo",
    label: "Thông tin người dùng",
    name: "Thông tin người dùng",
    layout: "",
    icon: <InfoCircleOutlined />,
    component: UserInfo,
  },
  {
    key: "5",
    path: "/cauhinhdulieu",
    label: "Cấu hình dữ liệu",
    name: "Cấu hình dữ liệu",
    layout: "",
    icon: <InfoCircleOutlined />,
    component: DaoTao,
  },
  {
    key: "6",
    path: "/mixdulieu",
    label: "",
    name: "Quản lý dữ liệu",
    layout: "",
    icon: <InfoCircleOutlined />,
    component: MixDuLieu,
  },
  // {
  //   key: "7",
  //   path: "/test",
  //   label: "",
  //   name: "Pilot",
  //   layout: "",
  //   icon: <InfoCircleOutlined />,
  //   component: Test,
  // },
];

export default dashboardRoutes;
