import React from "react";
import "ag-grid-enterprise";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { useRef } from "react";

const AgGridComponent = ({ columnDefs, rowData }) => {
  const gridApiRef = useRef(null);
  // optional as 'singleColumn' is the default group display type
  const groupDisplayType = "singleColumn";

  const onGridReady = (params) => {
    gridApiRef.current = params.api;
  };

  const exportToCsv = () => {
    if (gridApiRef.current) {
      gridApiRef.current.exportDataAsCsv();
    }
  };
  const exportToExcel = () => {
    if (gridApiRef.current) {
      gridApiRef.current.exportDataAsExcel();
    }
  };
  return (
    <div>
      <div>
        <button onClick={exportToCsv}>Export </button>
        <button onClick={exportToExcel}>Export to Excel</button>
      </div>
      <div className="ag-theme-alpine" style={{ height: 400, width: 1200 }}>
        <AgGridReact
          onGridReady={onGridReady}
          columnDefs={columnDefs}
          rowData={rowData}
          groupDisplayType={groupDisplayType}
        />
      </div>
    </div>
  );
};
export default AgGridComponent;
