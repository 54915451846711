import { Card } from "antd";
import React from "react";

export default function UserInfo() {
  const profile = JSON.parse(localStorage.getItem("auth"));

  return (
    <div className="site-card-wrapper">
      <Card title={profile.NickName}>
        <Card
          style={{ marginTop: 16 }}
          type="inner"
          title="Chức danh"
          // extra={<a href="#">More</a>}
        >
          {profile.TenChucDanh}
        </Card>
        <Card
          style={{ marginTop: 16 }}
          type="inner"
          title="Trưởng Khoa phòng"
          // extra={<a href="#">More</a>}
        >
          {profile.TenPhongBan} - {profile.TenTruongKhoaPhong}
        </Card>
        <Card
          type="inner"
          title={
            profile.PhanLoai === 1 ? "Điều dưỡng trưởng" : "Quản lý trực tiếp"
          }
          // extra={<a href="#">More</a>}
        >
          {profile.NguoiQuanLy}
        </Card>
        {profile.DieuDuongTruongBV && (
          <Card type="inner" title="Điều dưỡng trưởng BV">
            {profile.DieuDuongTruongBV}
          </Card>
        )}

        <Card
          style={{ marginTop: 16 }}
          type="inner"
          title="Hợp đồng"
          //  extra={<a href="#">More</a>}
        >
          {profile.LoaiHopDong}
        </Card>
        <Card
          style={{ marginTop: 16 }}
          type="inner"
          title="Quyền truy cập"
          //  extra={<a href="#">More</a>}
        >
          {profile.roleName}
        </Card>
        <Card
          style={{ marginTop: 16 }}
          type="inner"
          title="Mã nhân viên"
          //  extra={<a href="#">More</a>}
        >
          {profile.MaNV}
        </Card>
        <Card
          style={{ marginTop: 16 }}
          type="inner"
          title="Trưởng phòng Nhân sự"
          //  extra={<a href="#">More</a>}
        >
          {profile.TruongPhongNhanSu}
        </Card>
        <Card
          style={{ marginTop: 16 }}
          type="inner"
          title="Ban giám đốc"
          //  extra={<a href="#">More</a>}
        >
          {profile.GiamDoc}
        </Card>
      </Card>
    </div>
  );
}
