import React from "react";
import { Layout } from "antd";
import { Image } from "antd";
import routes from "../../routes";
import DynamicMenu from "./DynamicMenu";

const { Sider } = Layout;

const SideMenu = ({ collapsed }) => {
  return (
    <Sider
      trigger={null}
      collapsible
      defaultCollapsed
      collapsed={collapsed}
      breakpoint="lg"
      collapsedWidth="0"
      onBreakpoint={(broken) => {
        console.log("broken", broken);
      }}
      onCollapse={(collapsed, type) => {
        console.log("type", type);
      }}
    >
      <div className="logo">
        <Image preview={false} src="/logohmdn.png" />
      </div>
      <DynamicMenu routes={routes} />
      {/* Sử dụng thành phần DynamicMenu */}
    </Sider>
  );
};

export default SideMenu;
