import React from "react";
import { Route, Redirect } from "react-router-dom";
import Layout from "./Layout";
const AppRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(routeProps) =>
        localStorage.getItem("auth") ? (
          <Layout>
            <Component {...routeProps} />
          </Layout>
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: routeProps.location },
            }}
          />
        )
      }
    />
  );
};

export default AppRoute;
