import { Table, Input, Button, Space } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import React, { useState } from "react";
import { read, utils } from "xlsx";
import { format } from "date-fns";
//import { ChartComponent } from "../Chart/ChartComponent";

function ExcelDataImport() {
  const [excelData, setExcelData] = useState(null);
  const [filteredData, setFilteredData] = useState(null); // New state for filtered data
  const [columns, setColumns] = useState([]);
  const [filterInputs, setFilterInputs] = useState({});

  function handleFileUpload(event) {
    const file = event.target.files[0];
    clearData(); // Xóa dữ liệu cũ trước khi xử lý tệp mới
    const reader = new FileReader();
    reader.onload = function (e) {
      const data = new Uint8Array(e.target.result);

      // Gọi hàm xử lý dữ liệu từ tệp tin Excel
      processData(data);
    };

    reader.readAsArrayBuffer(file);
  }

  function soNguyenToNgay(soNguyen) {
    if (typeof soNguyen === "number" && !isNaN(soNguyen)) {
      const ngayBatDau = new Date(1900, 0, 1); // Ngày bắt đầu của Excel
      const soNgay = ngayBatDau.getDate() + soNguyen - 2; // Trừ 2 ngày để tính đúng

      const ngayChuyenDoi = new Date(ngayBatDau);
      ngayChuyenDoi.setDate(soNgay);

      return format(ngayChuyenDoi, "dd/MM/yyyy");
    }
  }

  // Sử dụng hàm
  const soNguyen = 44578;
  const ngayChuyenDoi = soNguyenToNgay(soNguyen);

  console.log(ngayChuyenDoi); // In ra: '17/01/2022'

  function processData(data) {
    const workbook = read(data, { type: "array" });
    const worksheet = workbook.Sheets[workbook.SheetNames[0]];
    const jsonData = utils.sheet_to_json(worksheet, { header: 1 });

    // Lấy mảng header từ jsonData
    const header = jsonData.slice(0, 1)[0];
    const result = header.map((item) => {
      return {
        title: item,
        dataIndex: item,
        key: item,
        ...getColumnSearchProps(item), // Thêm các thuộc tính filter vào cấu trúc cột
      };
    });
    setColumns(result);

    // Loại bỏ header khỏi mảng jsonData
    jsonData.shift();

    // Gán thuộc tính key từ header cho mỗi đối tượng trong mảng jsonData
    const excelDataWithKey = jsonData.map((data, index) => {
      const obj = { key: index };
      for (let i = 0; i < header.length; i++) {
        if (i === 10 || i === 11) {
          // console.log(data[0], data[10], soNguyenToNgay(data[10]));
          obj[header[i]] = soNguyenToNgay(data[i]);
        } else {
          obj[header[i]] = data[i];
        }
      }
      return obj;
    });

    // Lưu dữ liệu từ tệp tin Excel vào state
    setExcelData(excelDataWithKey);
    // Set filteredData to excelData initially
    setFilteredData(excelDataWithKey);
  }

  function getColumnSearchProps(dataIndex) {
    return {
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder={`Tìm kiếm ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Tìm kiếm
            </Button>
            <Button
              onClick={() => handleReset(clearFilters, dataIndex)}
              size="small"
              style={{ width: 90 }}
            >
              Reset
            </Button>
          </Space>
        </div>
      ),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
      onFilter: (value, record) => {
        const recordValue = record[dataIndex];
        return recordValue
          ? recordValue.toString().toLowerCase().includes(value.toLowerCase())
          : false;
      },
      render: (text) => {
        return filterInputs[dataIndex] ? (
          <span style={{ fontWeight: "bold" }}>{text}</span>
        ) : (
          text
        );
      },
    };
  }

  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setFilterInputs((prev) => ({
      ...prev,
      [dataIndex]: selectedKeys[0],
    }));
  }

  function handleReset(clearFilters, dataIndex) {
    clearFilters();
    setFilterInputs((prev) => {
      const updatedInputs = { ...prev };
      delete updatedInputs[dataIndex];
      return updatedInputs;
    });
  }

  function getFilteredData() {
    // Filter the data based on the current filterInputs state
    return filteredData.filter((record) => {
      return Object.keys(filterInputs).every((dataIndex) => {
        const filterValue = filterInputs[dataIndex];
        const recordValue = record[dataIndex]?.toString(); // Remove the toLowerCase() here

        if (
          typeof filterValue === "string" &&
          typeof recordValue === "string"
        ) {
          return recordValue.toLowerCase().includes(filterValue.toLowerCase());
        }
        return true; // Include the record in the filtered data if the filter value is not a string
      });
    });
  }

  function handleSubmit() {
    // Xử lý logic khi người dùng submit dữ liệu
    // Ví dụ: Gửi dữ liệu lên server, lưu vào cơ sở dữ liệu, hiển thị kết quả, vv.
    console.log(excelData);
    // Reset filteredData to original data after submitting
    setFilteredData(excelData);
  }
  function clearData() {
    setExcelData(null);
    setFilteredData(null);
    setColumns([]);
    setFilterInputs({});
  }

  return (
    <div>
      <input type="file" accept=".xlsx,.xls" onChange={handleFileUpload} />
      <button onClick={handleSubmit} disabled={!excelData}>
        Đẩy file
      </button>
      {filteredData ? ( // Add conditional rendering check for filteredData
        <div className="table-container">
          <Table
            pagination={{
              position: ["topLeft"],
              showTotal: (total, range) => {
                const filteredData = getFilteredData();
                return `Tổng có ${
                  filteredData ? filteredData.length : 0
                } (Hiển thị ${range[0]}-${range[1]} trong ${total})`;
              },
              showQuickJumper: true,
            }}
            virtual
            dataSource={getFilteredData()}
            columns={columns}
            onChange={(pagination, filters, sorter) => {
              // Update filtered values in state when filters change
              setFilterInputs(filters);
            }}
          />
          {/* <PDFViewer width="80%" height="500px">
       
            <Report data={getFilteredData()} />
          </PDFViewer> */}
        </div>
      ) : (
        <div>Upload a file to view the data.</div>
      )}
    </div>
  );
}

export default ExcelDataImport;
