import React from "react";
import "./../../index.css";
import { Form, Input, Button, message, Row, Col } from "antd";
import { withTranslation } from "react-i18next";
import { Image } from "antd";
import { useHistory } from "react-router-dom";
import API from "../../api";
import { LockOutlined, UserOutlined } from "@ant-design/icons";

const layout = {
  labelCol: {
    span: 8,
    offset: 2,
  },
  wrapperCol: {
    span: 4,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 11,
    span: 4,
  },
};

const Login = (props) => {
  const { t } = props;
  let history = useHistory();
  const onFinish = (values) => {
    const datapost = JSON.stringify({
      username: values.username,
      password: values.password,
    });
    API.post("/login_ldap", datapost)
      .then((response) => {
        if (response.data.code === 200) {
          if (response.data.MaNV) {
            localStorage.setItem("auth", JSON.stringify(response.data));
            history.push("/");
          } else {
            message.warning(
              "Đã đăng nhập AD  nhưng tài khoản đang bị khóa hoặc không khớp hệ thống HIS, vui lòng liên hệ IT"
            );
          }
        } else {
          message.warning(t("form.thongbaodangnhapsai"));
        }
      })
      .catch(function (error) {
        if (error.response) {
          message.warning(t("form.thongbaodangnhapsai"));
        }
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <>
      <Row>
        <Col span={24}>
          <div style={{ width: "30%", margin: "0 auto" }}>
            <Image preview={false} src="/logohmdn.png" />
          </div>
          <div className="site-layout-background" style={{ padding: 25 }}>
            <Form
              {...layout}
              name="login"
              className="login-form"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              label={"Login"}
            >
              <Form.Item label=""></Form.Item>
              <Form.Item
                label={t("form.username")}
                name="username"
                tooltip="Dùng tài khoản bạn hay đăng nhập vào máy tính tại HMĐN"
                rules={[
                  {
                    required: true,
                    message: t("form.vuilongnhap") + " " + t("form.username"),
                  },
                ]}
              >
                <Input
                  placeholder="Nhập tài khoản"
                  prefix={<UserOutlined className="site-form-item-icon" />}
                />
              </Form.Item>

              <Form.Item
                label={t("form.password")}
                name="password"
                rules={[
                  {
                    required: true,
                    message: t("form.vuilongnhap") + " " + t("form.password"),
                  },
                ]}
              >
                <Input.Password
                  prefix={<LockOutlined className="site-form-item-icon" />}
                  placeholder="Mật khẩu"
                />
              </Form.Item>
              <Form.Item {...tailLayout}>
                <Button type="primary" htmlType="submit">
                  {t("form.dangnhap")}
                </Button>
              </Form.Item>
            </Form>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default withTranslation("common")(Login);
