import React, { useEffect, useState } from "react";
import { DatePicker, Select, Button, PageHeader } from "antd";
import { Tabs } from "antd";
import API from "../../api";
import moment from "moment";
import { SearchOutlined } from "@ant-design/icons";
import ChartComponent from "../../components/Chart/ChartComponent";
import AgGridComponent from "../../components/TableAg";
const { RangePicker } = DatePicker;
export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: "Biễu diễn dữ liệu theo tùy chọn",
    },
  },
};
export default function Test() {
  const dateFormat = "DD/MM";
  const [paraSource, setParaSource] = useState();
  const [activeKey, setActiveKey] = useState("1"); // hoặc bất kỳ giá trị mặc định nào bạn muốn
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [
      {
        label: "Biểu đồ",
        data: [],
        borderColor: "rgb(86, 92, 8)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
    ],
  });
  const [filter_s, setFilter_s] = useState();

  const [filterDate, setFilterDate] = useState([
    moment().startOf("month"),
    moment().endOf("month"),
  ]);

  const [nameBaoCao, setNameBaoCao] = useState([]);
  const [dataTable, setDataTable] = useState([]);
  const [columns, setColumns] = useState([]);
  useEffect(() => {
    let content = { storename: "83_Get_DM_TruyVan", param: "" };
    const loadDmTruyVan = async () => {
      let response = await API.post("/databy", content);
      setParaSource(response.data);
    };
    loadDmTruyVan();
  }, []);

  useEffect(() => {
    const loadData = async () => {
      let storename = filter_s;
      let param = ``;
      try {
        const response = await API.post("/databys", { storename, param });
        // Kiểm tra xem response.data có tồn tại và là một mảng hay không
        if (response.data && Array.isArray(response.data)) {
          const data0 = response.data[0];
          const data1 = response.data[1]; //recordset header động

          const resultDinamic = data1.map((item) => {
            return {
              headerName: item.header,
              field: item.rows,
              key: item.key,
              sortable: item.sortable,
              rowGroup: item.rowGroup,
              filter: true,
              resizable: true,
            };
          });
          setColumns(resultDinamic);

          setDataTable(data0);

          // // Hàm để trích xuất dữ liệu từ mảng data0
          // const extractData = (data, property) => data.map((item) => item[property]);

          // // Lấy tất cả các thuộc tính là mảng label và data
          // const labels = Object.keys(data0[0]); // Giả sử tất cả các đối tượng có cùng cấu trúc
          // const data = labels.map((label) => extractData(data0, label));

          // // Gán dữ liệu cho biểu đồ
          // setChartData({
          //   labels: labels,
          //   datasets: data.map((values, index) => ({
          //     label: labels[index],
          //     data: values,
          //     borderColor: "rgb(100, 77, 1)",
          //     backgroundColor: "rgb(235, 253, 8)",
          //   })),
          // });

          setChartData({
            labels: data0.map((item) => item.label),
            datasets: [
              {
                label: nameBaoCao,
                data: data0.map((item) => item.value),
                borderColor: "rgb(100, 77, 1)",
                backgroundColor: "rgb(235, 253, 8)",
              },
            ],
          });
        } else {
          // Xử lý trường hợp response.data không phải là mảng
          console.error("Invalid data format:", response.data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    loadData();
  }, [filter_s]);
  console.log({ columns });
  const handleClick = () => {
    let from = moment(filterDate[0]).format("YYYY-MM-DD");
    let to = moment(filterDate[1]).format("YYYY-MM-DD");
    console.log(from, to);
  };

  const handleChangeDate = (e) => {
    setFilterDate([e[0]._d, e[1]._d]);
  };
  const handleTabChange = (key) => {
    console.log("Tab changed:", key);
    setActiveKey(key);
    // Thực hiện các hành động khác khi tab chuyển đổi
  };
  const handleChangeSelect = (value, option) => {
    setFilter_s(value);
    setNameBaoCao(option.children);
  };

  return (
    <>
      <div>
        <Tabs defaultActiveKey={activeKey} onChange={handleTabChange}>
          <Tabs.TabPane tab="Đào tạo" key="1"></Tabs.TabPane>
          <Tabs.TabPane tab="Đề án nghiên cứu khoa học" key="2">
            Đề án nghiên cứu khoa học
          </Tabs.TabPane>
          <Tabs.TabPane tab="Sáng kiến cải tiến" key="3">
            Sáng kiến cải tiến
          </Tabs.TabPane>
        </Tabs>
      </div>
      <div>
        <PageHeader
          className="site-page-header"
          subTitle="Chọn điều kiện lọc dữ liệu"
          extra={[
            <Button
              key="0"
              onClick={handleClick}
              type="primary"
              style={{ padding: "0px", margin: "0px" }}
              shape="circle"
              icon={<SearchOutlined />}
            />,
            <RangePicker
              key="1"
              style={{ width: "150px", margin: "0px" }}
              format={dateFormat}
              placeholder={["Từ ", " Đến"]}
              defaultValue={[
                moment().startOf("month"),
                moment().endOf("month"),
              ]}
              onChange={handleChangeDate}
            />,
            <Select
              key="2"
              onChange={handleChangeSelect}
              style={{ width: "350px", margin: "0px" }}
              showSearch
              allowClear
              placeholder="Gõ tìm báo cáo"
              optionFilterProp="children"
            >
              {paraSource &&
                paraSource.map((item, index) => (
                  <Select.Option key={index} value={item.value}>
                    {item.label}
                  </Select.Option>
                ))}
            </Select>,
          ]}
        />
        <div className="mixDulieu">
          <div>
            <AgGridComponent rowData={dataTable} columnDefs={columns} />
          </div>
          <div>
            <ChartComponent options={options} data={chartData} />
          </div>
        </div>
      </div>
    </>
  );
}
